import React, { useContext, useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { graphql, navigate, PageProps } from 'gatsby';

import styled from 'styled-components';

import { Button, ButtonLink } from '../components/Atoms/Button/Button';
import { B2, Caption, H2, H4 } from '../components/Atoms/Typography/Typography';
import CTABanner from '../components/FlexibleComponents/CTABanner/CTABanner';
import ResourceHeroBanner from '../components/FlexibleComponents/ResourceHeroBanner/ResourceHeroBanner';
import ModalSet from '../components/FlexibleSetComponents/ModalSet/ModalSet';
import GeneralFormComponent from '../components/Forms/GeneralFormComponent/GeneralFormComponent';
import CaseStudyHeader from '../components/Molecules/CaseStudyHeader/CaseStudyHeader';
import CustomerBio from '../components/Molecules/CustomerBio/CustomerBio';
import EventBriteButton from '../components/Molecules/EventBriteButton/EventBriteButton';
import { ExploreMoreResources } from '../components/Molecules/ExploreMoreResources/ExploreMoreResources';
import SEO from '../components/SEO/SEO';
import { SiteFooter } from '../components/SiteFooter/SiteFooter';
import { SiteGrid } from '../components/SiteGrid/SiteGrid';
import SiteHeader from '../components/SiteHeader/SiteHeader';
import Wrapper, { WrapperStyles } from '../components/Wrapper/Wrapper';
import PageContext from '../hooks/pageContextProvider';
import { getLocaleSlug } from '../hooks/useLocale';
import { renderRichTextNode } from '../hooks/useRichText';
import useStructuredData from '../hooks/useStructuredData';
import { desktopOnly } from '../style/desktopHelpers';
import fontGet from '../style/fontGet';
import themeGet from '../style/themeGet';
import { ILink } from '../types/types';
import replaceLocaleUrl from '../utils/replaceLocaleUrl';
import SocialShare from '../components/Molecules/SocialShare/SocialShare';
import ContentfulImage from '../components/Atoms/ContentfulImage/ContentfulImage';

const ResourceMain = styled.main`
  background: ${themeGet('color.primary.white')};
`;

const ResourceGrid = styled(SiteGrid)`
  grid-template-columns: repeat(2, 0fr);
  ${desktopOnly} {
    grid-template-columns: repeat(8, 1fr);
  }
  position: relative;
`;

const RichTextBody = styled.div`
  ${WrapperStyles}
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  ${desktopOnly} {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    width: 100%;
  }
`;

const HideOverFlow = styled.div`
  overflow-x: hidden;
`;

const ResourceBody = styled.div`
  padding-bottom: ${themeGet('spacing.s3')};
  position: relative;

  ${RichTextBody} {
    > p {
      margin-bottom: ${themeGet('spacing.s5')};
      ${fontGet('b2c')};
      grid-column: span 2;

      ${desktopOnly} {
        grid-column: 3 / span 5;
      }
    }

    .gatsby-image-wrapper {
      grid-column: span 2;
      margin: ${themeGet('spacing.s5')} 0;

      ${desktopOnly} {
        grid-column: 3 / span 5;
      }
    }

    > img {
      grid-column: span 2;
      margin: ${themeGet('spacing.s5')} 0;
      max-width: 100%;

      ${desktopOnly} {
        grid-column: 3 / span 5;
      }
    }

    > h1,
    > h2 {
      margin-top: ${themeGet('spacing.s3')};
      grid-column: span 2;
      ${desktopOnly} {
        grid-column: 3 / span 5;
      }
    }

    > h3,
    h4 {
      grid-column: span 2;

      ${desktopOnly} {
        grid-column: 3 / span 5;
      }
    }

    > hr {
      grid-column: span 2;

      ${desktopOnly} {
        grid-column: 3 / span 5;
      }
    }

    > ul,
    ol {
      grid-column: span 2;
      margin-bottom: ${themeGet('spacing.s5')};
      line-height: 3.2rem;
      font-size: 2.1rem;
      ${desktopOnly} {
        grid-column: 3 / span 5;
      }
    }

    .rich-text-ul {
      margin-left: 32px;
      list-style-position: inherit;
    }
  }
`;

const Title = styled(H2)`
  margin-bottom: ${themeGet('spacing.s5')} 0;
  grid-column: 2 / span 6;
  word-break: break-word;

  ${desktopOnly} {
    margin-top: 0;
    grid-column: 3 / span 5;
  }
`;

const Author = styled(B2)`
  grid-column: 1 / 4;

  margin-bottom: ${themeGet('spacing.s5')};
  ${desktopOnly} {
    grid-column: 3 / span 5;
  }
`;

const DateCaption = styled(Caption)`
  grid-column: 5 / 8;

  margin-bottom: ${themeGet('spacing.s5')};
  ${desktopOnly} {
    grid-column: 3 / span 5;
  }
`;

const Intro = styled(H4)`
  grid-column: 1 / 8;
  margin-bottom: ${themeGet('spacing.s5')};

  ${desktopOnly} {
    grid-column: 3 / span 5;
  }

  .styled-rich-text:not(:first-of-type) {
    margin-top: ${themeGet('spacing.s5')};
  }
`;

const StyledHR = styled.hr`
  border: none;
  border-bottom: 1px solid black;
  grid-column: span 7;
  margin-bottom: ${themeGet('spacing.mobile.s5')};

  ${desktopOnly} {
    grid-column: 3 / span 5;
    margin-bottom: ${themeGet('spacing.s3')};
  }
`;

const BackSection = styled.div`
  display: flex;
  align-items: flex-start;
  grid-column: 1 / span 4;
  padding: 32px 0;
  justify-content: space-between;

  ${desktopOnly} {
    grid-column: 3 / span 5;
  }
  a {
    display: none;
    ${desktopOnly} {
      display: flex;
    }
  }
`;

const BackSectionCaseStudy = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  grid-column: 1 / 8;
  padding: ${themeGet('spacing.s5')} 0;

  ${desktopOnly} {
    grid-column: 3 / span 5;
  }
  a {
    display: none;
    ${desktopOnly} {
      display: flex;
    }
  }
`;

const BackSectionContainer = styled(Wrapper)`
  background-color: ${themeGet('color.background.lightBeige')};

  ${desktopOnly} {
    background-color: ${themeGet('color.background.softWhite')};
  }
`;

const ButtonLinkWrapper = styled.div`
  ${desktopOnly} {
    align-self: center;
  }

  span {
    margin-right: 5px;
  }
`;

const MobileResourceContainer = styled.div`
  background-color: ${themeGet('color.background.lightBeige')};
  padding-top: ${themeGet('spacing.mobile.s4')};
  padding-bottom: ${themeGet('spacing.mobile.s4')};
  ${desktopOnly} {
    display: none;
  }
`;

const StickyWrapper = styled(Wrapper)`
  pointer-events: none;
  display: none;
  height: 100%;
  left: 0;
  padding-top: ${themeGet('spacing.s1')};
  padding-bottom: ${themeGet('spacing.s1')};
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 99;
  ${desktopOnly} {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: ${themeGet('spacing.s3')};
  }
`;

const ZestStickyWrapper = styled(StickyWrapper)`
  padding-top: 0;
  height: 85%;
`;

const StickyResourceColumnContainer = styled.div`
  grid-column: 1 / span 2;
  height: 100%;
  pointer-events: auto;
  position: relative;
`;

const StickyResourceColumn = styled.div`
  ${desktopOnly} {
    position: sticky;
    top: 150px;
  }
`;

const EventBriteContainer = styled.div`
  margin: ${themeGet('spacing.mobile.s5')} 0;

  ${desktopOnly} {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    margin-top: 0;
    width: 100%;

    & > * {
      grid-column: 3;
    }
  }
`;

const AuthorsTitle = styled(Author)`
  margin-top: ${themeGet(`spacing.mobile.s6`)};
  margin-bottom: 5px;
  ${desktopOnly} {
    font-size: 1.8rem;
  }
  font-size: 1.6rem;
`;

const AuthorsImageContainer = styled.div`
  width: 30%;
  ${desktopOnly} {
    margin-bottom: ${themeGet('spacing.s2')};
    width: 70%;
  }
`;

const MobileAuthorsContainer = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 25px;
  gap: 15px;
  ${desktopOnly} {
    display: none;
  }
`;

const ResourcePage: React.FC<PageProps<any>> = (props: any) => {
  const website = props.data?.contentfulWebsite;
  const pageType = props.pageContext?.pageType;
  const pageData = props.data?.contentfulComposePage;
  const seo = pageData?.seo;
  const headerData = props.data?.allContentfulComponentSiteHeader?.nodes?.[0];
  const stickyBanner = props.data?.allContentfulComponentStickyBanner?.nodes;
  const footerData = props.data?.allContentfulComponentSiteFooter?.nodes?.[0];
  const customTheme = pageData?.customTheme;
  const content = pageData?.content?.content || {};
  const slug = pageData?.slug;
  const location = props.location;
  const shareTitle = pageData?.title;
  const shareUrl = `${location?.href}`;
  const alternativeLocales = props.data?.alternativeLocales?.distinct || [];
  const publishDate = new Date(content?.date);
  const microCopy = props.data?.allContentfulContentMicrocopy?.nodes?.[0];
  const authorsImage = pageData?.content?.authors;
  const ctaContent =
    props.data?.allContentfulContentMicrocopy?.nodes?.[0].resourcesCtaBanner;
  const CTABannerContent = {
    content: {
      ...ctaContent,
    },
  };
  // setup if modal exists
  const link = content?.link as ILink;
  const showResourceBar = link !== undefined;
  const modalElement = link && link?.modal && (
    <ModalSet
      trigger={<Button as="span">{link.text}</Button>}
      data={link}
      {...link.modal}
    />
  );

  const linkButton = link && link.text && (
    <Button as="a" href={link?.externalUrl} target="_self" tabIndex="0">
      {link.text}
    </Button>
  );

  const { locale } = useContext(PageContext);

  const scripting = pageData?.content?.content?.scripting?.raw;
  const structuredData = useStructuredData(scripting);

  useEffect(() => {
    const isFrenched = localStorage.getItem('isFrenched');
    if (isFrenched) {
      return;
    }

    fetch('https://ipapi.co/json/', { method: 'GET' })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data?.region === 'Quebec') {
          localStorage.setItem('isFrenched', 'true');
          const neededUrl = replaceLocaleUrl(location?.pathname, 'fr-fr');
          return navigate(neededUrl);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {structuredData && (
        <Helmet>
          <script type="application/ld+json">{structuredData}</script>
        </Helmet>
      )}
      <SEO
        siteName={website?.siteName}
        title={pageData.title}
        description={
          seo?.description ? seo?.description.description : pageData.title
        }
        location={location}
        alternativeLocales={alternativeLocales}
        slug={slug}
        noIndex={seo?.seoIndex === 'No'}
        image={seo?.shareImage}
        locale={locale}
      />
      <SiteHeader
        content={headerData}
        stickyBanner={stickyBanner}
        customTheme={customTheme}
      />
      <ResourceMain id="main-content" role="main">
        <ResourceBody>
          {showResourceBar && (
            <StickyWrapper>
              <StickyResourceColumnContainer>
                <StickyResourceColumn>{modalElement}</StickyResourceColumn>
              </StickyResourceColumnContainer>
            </StickyWrapper>
          )}
          {!modalElement && showResourceBar && (
            <StickyWrapper>
              <StickyResourceColumnContainer>
                <StickyResourceColumn>{linkButton}</StickyResourceColumn>
              </StickyResourceColumnContainer>
            </StickyWrapper>
          )}
          <HideOverFlow>
            {pageType === 'ContentfulPageCaseStudy' && (
              <>
                <Wrapper>
                  <ResourceGrid>
                    <CaseStudyHeader {...content} theme={customTheme} />
                  </ResourceGrid>
                </Wrapper>
                <BackSectionContainer>
                  <ResourceGrid>
                    <BackSectionCaseStudy>
                      <ButtonLinkWrapper>
                        <ButtonLink
                          variant="secondary"
                          to={getLocaleSlug('/resources', locale)}
                          aria-label={`${microCopy.resourcesBackToResources}`}
                        >
                          <span aria-hidden="true">{`<`}</span>
                          {`${microCopy.resourcesBackToResources}`}
                        </ButtonLink>
                      </ButtonLinkWrapper>
                      <SocialShare
                        shareUrl={shareUrl}
                        shareTitle={shareTitle}
                      ></SocialShare>
                    </BackSectionCaseStudy>
                  </ResourceGrid>
                </BackSectionContainer>
                <Wrapper>
                  <ResourceGrid>
                    <ResourceHeroBanner
                      content={{
                        featureImage: content?.featureImage,
                        video: content?.video,
                      }}
                      appearance={{ theme: content?.video ? 'Dark' : 'Light' }}
                    />
                  </ResourceGrid>
                </Wrapper>
              </>
            )}
            {pageType !== 'ContentfulPageCaseStudy' && (
              <>
                <BackSectionContainer>
                  <ResourceGrid>
                    <BackSection>
                      <ButtonLinkWrapper>
                        <ButtonLink
                          variant="secondary"
                          to={getLocaleSlug('/resources', locale)}
                          aria-label={`${microCopy.resourcesBackToResources}`}
                        >
                          <span aria-hidden="true">{`<`}</span>
                          {`${microCopy.resourcesBackToResources}`}
                        </ButtonLink>
                      </ButtonLinkWrapper>
                      <SocialShare
                        shareUrl={shareUrl}
                        shareTitle={shareTitle}
                      ></SocialShare>
                    </BackSection>
                  </ResourceGrid>
                </BackSectionContainer>
                <Wrapper>
                  <ResourceGrid>
                    <Title as="h1">{content?.primaryText}</Title>
                    <Author>{content?.author?.primaryText}</Author>
                    <DateCaption>
                      {publishDate.toDateString().substring(4)} |{' '}
                      {content?.type?.primaryText}
                    </DateCaption>
                    <ResourceHeroBanner
                      content={{
                        featureImage: content?.featureImage,
                        video: content?.video,
                      }}
                      appearance={{ theme: content?.video ? 'Dark' : 'Light' }}
                    />
                  </ResourceGrid>
                </Wrapper>
                {pageType === 'ContentfulPageZest' && (
                  <MobileAuthorsContainer>
                    {authorsImage &&
                      authorsImage.map((image) => (
                        <AuthorsImageContainer>
                          <ContentfulImage image={image} />
                          <AuthorsTitle>{image.description}</AuthorsTitle>
                        </AuthorsImageContainer>
                      ))}
                  </MobileAuthorsContainer>
                )}
                {pageType === 'ContentfulPageZest' && (
                  <ZestStickyWrapper>
                    <StickyResourceColumnContainer>
                      {authorsImage &&
                        authorsImage.map((image) => (
                          <AuthorsImageContainer>
                            <ContentfulImage image={image} />
                            <AuthorsTitle>{image.description}</AuthorsTitle>
                          </AuthorsImageContainer>
                        ))}
                    </StickyResourceColumnContainer>
                  </ZestStickyWrapper>
                )}
                <Wrapper>
                  <ResourceGrid>
                    {content?.introText && (
                      <Intro as="div">
                        {renderRichTextNode(content?.introText)}
                      </Intro>
                    )}
                    <StyledHR />
                  </ResourceGrid>
                </Wrapper>
              </>
            )}
            {showResourceBar && (
              <MobileResourceContainer>
                <Wrapper>{modalElement}</Wrapper>
              </MobileResourceContainer>
            )}
            {content?.eventBriteId && content?.eventBriteLabel && (
              <Wrapper>
                <EventBriteContainer>
                  <EventBriteButton
                    eventId={content.eventBriteId}
                    label={content.eventBriteLabel}
                  />
                </EventBriteContainer>
              </Wrapper>
            )}
            <RichTextBody>{renderRichTextNode(content?.bodyText)}</RichTextBody>
            {content?.form && <GeneralFormComponent {...content.form} />}
            <Wrapper>
              <SiteGrid>
                {pageType === 'ContentfulPageCaseStudy' &&
                  content.testimonial && (
                    <CustomerBio {...content?.testimonial} />
                  )}
              </SiteGrid>
            </Wrapper>
          </HideOverFlow>
        </ResourceBody>
        <ExploreMoreResources type={content?.type} copy={microCopy} />
        <CTABanner key={CTABannerContent.content.id} {...CTABannerContent} />
      </ResourceMain>
      <SiteFooter {...footerData} customTheme={customTheme} />
    </>
  );
};

export default ResourcePage;

export const data = graphql`
  query ResourcePageQuery(
    $id: String
    $locale: String
    $contentful_id: String
  ) {
    contentfulWebsite {
      siteName
    }
    alternativeLocales: allContentfulComposePage(
      filter: {
        contentful_id: { eq: $contentful_id }
        publishInLocale: { eq: "Yes" }
      }
    ) {
      distinct(field: node_locale)
    }
    contentfulComposePage(id: { eq: $id }) {
      id
      contentful_id
      title
      slug
      customTheme
      content {
        ...InsightsPageFragment
        ...ResearchPageFragment
        ...NewsPageFragment
        ...EventPageFragment
        ...CaseStudyPageFragment
        ...ZestPageFragment
      }
      seo {
        ...SEOFragment
      }
    }

    allContentfulComponentStickyBanner(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        ...StickyBannerFragment
      }
    }

    allContentfulComponentSiteHeader(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...SiteHeaderFragment
      }
    }

    allContentfulComponentSiteFooter(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...SiteFooterFragment
      }
    }

    allContentfulContentMicrocopy(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...MicroCopyFragment
      }
    }
  }
`;
